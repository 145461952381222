<template lang="pug">
  b-card.manager-genders-form.card-white.card-no-gutters.shadow(no-body)
    b-card-header.bg-transparent.border-0
      h5.m-0
        | {{ $t('activerecord.models.gender.one') }}
    b-form.form-wc.form-genders-form.mt-sm-3(
      @submit.stop.prevent='formSubmit'
      @input.stop.prevent='formChange'
      @reset.stop.prevent='formReset')
      b-card-body

        wc-forms-alerts(v-if='formShowErrors' :errors='form.errors.base')

        transition(name='fade')
          fieldset(v-if='formShow' :disabled='formDisable')
            wc-forms-translations(
              v-for='(locale, key) in $wc.conf.base.locales'
              :key='key'
              :locale='locale'
              :lc='key')
              b-collapse(
                :id='`tl-${key}`'
                :ref='`${key}Tl`'
                :visible="action === 'create' ? true : formShowSection(key)"
                role='tabpanel')
                b-card-body
                  b-form-group(
                    :id='`form-gender-name_${key}`'
                    :label="$t('activerecord.attributes.shared.name') + ' ' + $t(`locales.${key}`)"
                    label-for='`gender-name_${key}`'
                    label-cols-md='2'
                    label-align-md='right')
                    b-form-input.shadow-sm.is-required(
                      :id='`gender-name_${key}`'
                      name='`name_${key}`'
                      type='text'
                      autocomplete='name'
                      :placeholder="$t('gender.placeholders.name')"
                      :state='formValidationState($v.form.data.attributes[`name_${key}`])'
                      @focus.native='formChange'
                      v-model='$v.form.data.attributes[`name_${key}`].$model'
                      aria-describedby='`gender-name_${key}-feedback`'
                      trim)
                    wc-forms-if(
                      :id='`gender-name_${key}-feedback`'
                      extra-class='ml-feedback'
                      :attribute='$v.form.data.attributes[`name_${key}`]'
                      :remote="formRemoteInvalidFeedback(`name_${key}`)"
                      :validators='{ required: {}, maxLength: {} }')

      b-card-footer.border-0.bg-white
        wc-forms-buttons#form-gender-buttons(:disabled='formDisable || $v.form.data.$invalid')

    loading(:active.sync='isLoading' :can-cancel-esc='true' :on-cancel='onCancel')
</template>

<script>
import WcFormsTranslations from '@components/shared/forms/WcFormsTranslations'
import WcFormsButtons from '@components/shared/forms/WcFormsButtons'
import apiGenders from '@services/api/manager/globals/genders'
import { Form } from '@common/form'
import { required, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'manager-genders-form',
  mixins: [Form],
  components: {
    WcFormsTranslations,
    WcFormsButtons,
  },
  computed: {
    apiParams() {
      return {
        get: [{ id: this.gender_id || 'new' }, {}],
        create: [
          {},
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
        update: [
          { id: this.gender_id },
          { [this.apiModel]: this.formSubmitData(this.$getDeep(this.form, 'data.attributes')) },
        ],
      }
    },
  },
  validations() {
    let validations = { form: { data: { attributes: {} } } }
    for (let locale of this.$wc.conf.base.available_locales) {
      validations.form.data.attributes[`name_${locale}`] = {
        required,
        maxLength: maxLength(this.$wc.conf.limits.max_name),
        remote: () => this.formRemoteValid(`name_${locale}`),
      }
      validations.form.data.attributes[`${locale}Tl`] = [`form.data.attributes.name_${locale}`]
    }
    return validations
  },
  data() {
    return {
      apiBase: apiGenders,
      apiModel: 'gender',
      gender_id: this.$route.params.gender_id,
    }
  },
}
</script>
