<template lang="pug">
  b-card.wc-forms-translations.card-white.card-no-gutters.shadow.mb-2(no-body)
    b-card-header.border-0.p-0(role='tab')
      b-link.d-block.px-4.py-3.text-body(href='#' v-b-toggle='`tl-${$vnode.key}`')
        i.fal.fa-fw.fa-flag.text-primary
        |  {{ $t('activerecord.attributes.shared.translation') }} {{ $t(`locales.${lc}`) }}
    slot
</template>

<script>
export default {
  name: 'wc-forms-translations',
  props: {
    locale: {
      type: Object,
      required: true,
    },
    lc: {
      type: String,
      required: true,
    },
  },
}
</script>
